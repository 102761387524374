import React from "react";
import { css } from "twin.macro";

export interface Props {
  viewerRef?: any;
  backend?: any;
  src?: any;
}

const viewerSize = css`
  width: 100%;
  height: 82vh;
`;

export default class PDFViewer extends React.Component<Props> {
  viewerRef = React.createRef();
  backend = new this.props.backend();

  componentDidMount() {
    const { src } = this.props;
    const element = this.viewerRef.current;

    this.backend.init(src, element);
  }

  render() {
    return <div ref={this.viewerRef as React.RefObject<HTMLDivElement>} id="viewer" css={viewerSize} />;
  }
}
