import { getLpUrl } from "../../utils/common";

export default class PDFJs {
  init = (source: string, element: { appendChild: (arg0: HTMLIFrameElement) => void }) => {
    const iframe = document.createElement("iframe");
    const viewerHostName = getLpUrl();

    iframe.src = `/pdfjs-2.10.377-dist/web/viewer.html?file=${source}&self_host=${viewerHostName}`;
    iframe.width = "100%";
    iframe.height = "100%";

    element.appendChild(iframe);
  };
}
