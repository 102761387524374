import { Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import tw, { styled } from "twin.macro";

import { Container } from "./Container";
import { Layout } from "./Layout/Layout";

interface Props {
  pdf: string;
}

const Root = styled(Container)`
  ${tw`w-full mt-6`}
`;

const Disclaimer: React.FC<Props> = ({ children, pdf, ...rest }) => {
  return (
    <Layout altFooter={false}>
      <Root {...rest}>
        {children}
        <Trans i18nKey="pdfLink">
          [Would you like a pdf copy? <a href={pdf}>Click here.</a>]
        </Trans>
      </Root>
    </Layout>
  );
};

export default Disclaimer;
